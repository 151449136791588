//#region Imports

import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material";

//#endregion

//#region Components

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss']
})

//#endregion

/**
 * A classe que exibe uma modal para exibir o texto e uma mensagem
 */
export class BasicModalComponent {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) { title, message }: any
  ) {
    this.title = title;
    this.message = message;
  }

  //#endregion

  //#region Public Properties

  /**
   * O titulo da modal
   */
  public title: string;

  /**
   * A mensagem da modal
   */
  public message: string;

  //#endregion

}

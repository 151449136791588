//#region Imports

import { Component } from '@angular/core';

//#endregion

//#region Components

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})

//#endregion

/**
 * A classe que exibe uma modal para exibir um loading
 */
export class LoadingModalComponent {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor() {}

  //#endregion
}

import { HttpClientModule } from "@angular/common/http";
import { AngularFireModule } from "@angular/fire";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { MatDialogModule, MatProgressSpinnerModule } from "@angular/material";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from "../environments/environment";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingModalComponent } from "./components/loading-modal/loading-modal.component";
import { NoAppModalComponent } from "./components/no-app-modal/no-app-modal.component";
import { HomeComponent } from './pages/home/home.component';
import { BasicModalComponent } from './components/basic-modal/basic-modal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BasicModalComponent,
    NoAppModalComponent,
    LoadingModalComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
  ],
  entryComponents: [
    BasicModalComponent,
    NoAppModalComponent,
    LoadingModalComponent,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }

//#region Imports

import { HttpClient } from "@angular/common/http";
import { Component } from '@angular/core';
import { AngularFireFunctions } from "@angular/fire/functions";
import { MatDialog } from "@angular/material";

import { BaseValidateEmailComponent } from "../../shared/base-validate-email.component";

//#endregion

//#region Components

@Component({
  selector: 'app-no-app-modal',
  templateUrl: './no-app-modal.component.html',
  styleUrls: ['./no-app-modal.component.scss']
})

//#endregion

/**
 * A classe que exibe uma modal para exibir o texto e uma mensagem
 */
export class NoAppModalComponent extends BaseValidateEmailComponent {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
  ) {
    super(dialog, http);
  }

  //#endregion

}

//#region Imports

import { HttpClient } from "@angular/common/http";
import { AfterContentInit, ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { MatDialog } from "@angular/material";
import { MatCarouselComponent } from "@ngmodule/material-carousel";

import { NoAppModalComponent } from "../../components/no-app-modal/no-app-modal.component";
import { BaseValidateEmailComponent } from "../../shared/base-validate-email.component";

//#endregion

//#region Components

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

//#endregion

/**
 * A classe que representa a página de início
 */
export class HomeComponent extends BaseValidateEmailComponent implements AfterContentInit {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
    private changes: ChangeDetectorRef,
  ) {
    super(dialog, http);
  }

  //#endregion

  //#region Public Properties

  /**
   * A proporção do carrousel
   */
  public carouselProportion: number = 45;

  //#endregion

  //#region LifeCycle Events

  /**
   * Método executado após iniciar o conteúdo
   */
  public ngAfterContentInit(): void {
    this.onResize(undefined);
  }

  //#endregion

  //#region Public Methods

  /**
   * Método executado ao clicar no icone do Instagram
   */
  public onClickInstagram(): void {
    window.open("https://www.instagram.com/myhomeyours/");
  }

  /**
   * Método executado ao clicar no icone do Facebook
   */
  public onClickFacebook(): void {
    window.open("https://www.instagram.com/myhomeyours/");
  }

  /**
   * Método executado ao clicar no icone do Youtube
   */
  public onClickYoutube(): void {
    window.open("https://www.youtube.com/channel/UCaZ9k9prXVyhWOBn95XXf3g");
  }

  /**
   * Método executado ao clicar no icone da Google Play
   */
  public onClickGooglePlay(): void {
    window.open("https://play.google.com/store/apps/details?id=com.zoetech.myhomeyours");
  }

  /**
   * Método executado ao clicar no icone da App Store
   */
  public onClickAppStore(): void {
    window.open("https://apps.apple.com/br/app/myhomeyours/id1610523684");
  }

  /**
   * Método executado ao clicar na seta da esquerda
   */
  public onClickLeftArrow(carousel: MatCarouselComponent): void {
    carousel.previous();
  }

  /**
   * Método executado ao clicar na seta da direita
   */
  public onClickRightArrow(carousel: MatCarouselComponent): void {
    carousel.next();
  }

  /**
   * O evento executado quando a página é redimencionada
   *
   * @param event O evento para quando ele redimencionar
   */
  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.carouselProportion = (-45 / 1600) * (window.innerWidth - 320) + 70;

    if (window.innerHeight < 480)
      this.carouselProportion += 8;

    this.changes.detectChanges();
  }

  //#endregion

}

//#region Imports

import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";

import { BasicModalComponent } from "../components/basic-modal/basic-modal.component";
import { LoadingModalComponent } from "../components/loading-modal/loading-modal.component";

//#endregion

export class BaseValidateEmailComponent {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
  ) {}

  //#endregion

  //#region Public Methods

  /**
   * Método executado ao clicar no botão de salvar e-mail
   *
   * @param emailInput O elemento que armazena endereço de e-mail da pessoa
   */
  public async onClickSendEmail(emailInput: HTMLInputElement): Promise<void> {
    const email = emailInput.value;

    if (email.trim().length === 0)
      return;

    if (!this.isValidEmail(email.trim()))
      return this.showDialogMessage("Alerta!", "É preciso digitar um e-mail válido!");

    const loadingDialog = this.dialog.open(LoadingModalComponent, { disableClose: true, panelClass: 'loading-dialog' });

    const url = 'https://us-central1-my-homeyours.cloudfunctions.net/homeyours/api/v1/email';
    const tryInsert = await this.http.post<any>(url, { email }).toPromise()
      .then(({ message = '', statusCode = 500 }) => { return { message, statusCode }; })
      .catch(({ error: { message = '', statusCode = 500 } = {}}) => { return { message, statusCode }; });

    loadingDialog.close();

    if (tryInsert.statusCode === 400)
      this.showDialogMessage("Alerta!", tryInsert.message);
    else if (tryInsert.statusCode === 200) {
      emailInput.value = "";
      this.dialog.closeAll();

      this.showDialogMessage("Pronto!", tryInsert.message);
    } else
      this.showDialogMessage("Erro!", "Infelizmente ocorreu um erro, por favor, tente novamente!");
  }

  /**
   * Método que exibe um dialog com uma mensagem
   *
   * @param title O titulo da mensagem
   * @param message O conteúdo da mensagem
   */
  public showDialogMessage(title: string, message: string): void {
    this.dialog.open(BasicModalComponent, {
      data: {
        title,
        message,
      }
    });
  }

  /**
   * Diz se o e-mail é válido
   *
   * @param email O e-mail a ser verificado
   */
  public isValidEmail(email: string): boolean {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  }

  //#endregion
}
